

//export const base_url = 'http://localhost:1337' 
//export const img_base_url = 'http://localhost:1337'
//export const base_url = 'http://192.168.0.169:1337'  //MTN ROUTEUR
////////===export const base_url = 'https://resto-prl.herokuapp.com'  //HEROKU

//EVOLUTIO TEST
//export const base_url = 'https://resto-back.evolutiosystem.com'  //evolution SYSTEM
//export const img_base_url = 'https://resto-back.evolutiosystem.com'  //evolution SYSTEM IMAGE URL FOR SAILS SERVER

/////export const base_url = 'http://192.168.10.49:1337'  //MOOV ROUTEUR

//////////LOCAL
  //export const base_url = 'http://192.168.10.55:1337'  //MOOV ROUTEUR new pc
 //export const img_base_url = 'http://192.168.10.55:1337'  //IMAGE URL FOR SAILS SERVER

  export const img_server = 'SAILS'  // EXPRESS or SAILS
//////export const base_url = 'http://192.168.43.55:1338'  //MOOV ROUTEUR   PC SAMI

//export const base_url = ' https://jdd-perlene.herokuapp.com' 

// const base_url = ' https://jdd-test.herokuapp.com'
//export const base_url ='http://logicielperlene.com/app'

////export const img_base_url = 'http://192.168.10.49:5000'  //IMAGE URL FOR EXPRESS

 

//TEST 
//export const base_url = 'https://resto-back.evolutiosystem.com'
///export const img_base_url = 'https://resto-img.evolutiosystem.com'  //IMAGE URL FOR ONLINE SERVER
//export const img_base_url = 'https://resto-back.evolutiosystem.com'  //IMAGE URL FOR ONLINE SERVER

// LA MARTINE
//export const base_url = 'https://martine-back.evolutiosystem.com'  //MOOV ROUTEUR new pc
//export const img_base_url = 'https://resto-back.evolutiosystem.com'  //IMAGE URL FOR ONLINE SERVER

// VAL DE DICK CLIB HOTEL
//export const base_url = 'https://vdc-hotel-back.perlenerestaurant.com'  //MOOV ROUTEUR new pc
//export const img_base_url = 'https://vdc-hotel-back.perlenerestaurant.com'  //IMAGE URL FOR ONLINE SERVER

// VAL DE DICK CLIB JARDINS
//export const base_url = 'https://vdc-jardins-back.perlenerestaurant.com'  //MOOV ROUTEUR new pc
//export const img_base_url = 'https://vdc-jardins-back.perlenerestaurant.com'  //IMAGE URL FOR ONLINE SERVER

// VAL DE DICK CLIB ROOFTOP
//export const base_url = 'https://vdc-rooftop-back.perlenerestaurant.com'  //MOOV ROUTEUR new pc
//export const img_base_url = 'https://vdc-rooftop-back.perlenerestaurant.com'  //IMAGE URL FOR ONLINE SERVER

// VAL DE DICK CLIB VIP
export const base_url = 'https://vdc-vip-back.perlenerestaurant.com'  //MOOV ROUTEUR new pc
export const img_base_url = 'https://vdc-vip-back.perlenerestaurant.com'  //IMAGE URL FOR ONLINE SERVER


